const joinWithN = (data) => {
  return Object.keys(data)
    .map(key => `${key}=${data[key]}`)
    .join("&");
}

const filePath = {
  static: (fileInfo) => `${fileInfo.contentType.replace(/\s/g, '-')}/${fileInfo.fileName.replace(/xml/g, 'json')}`,
  dynamic: (fileInfo) => `((TeamSite/Templating/DCR/Type:${fileInfo.contentType}) AND (Path:${fileInfo.fileName}))`
}

const projectPath = {
  static: (projectName) => `resources/data/${projectName.replace(/\s/g, '-')}`,
  dynamic: (projectName) => `/default/main/${projectName}`
}
const projectName = 'Portal Home'

const defaultJSONParams = {
  includeDCRContent: true,
  start: 0,
  max: 100,
  format: "json"
}

const s3 = 'https://s3-ap-east-1.amazonaws.com/webstatic-publish.phrhk-sit.internal'
const homeSIT = '//home-sit.hktdc.com'
const homeUAT = '//home-uat.hktdc.com'
const homePRD = '//home.hktdc.com'

const previewSIT = 'http://cmsappdev01.hktdc.org/lscs/v1/document'
const previewUAT = 'http://cmsappuat01.hktdc.org/lscs/v1/document'
const previewPRD = 'http://cms.tdc.org.hk/lscs/v1/document'

const apiSIT = 'https://api-phr-sit.hktdc.com'
const apiUAT = 'https://api-phr-uat.hktdc.com'
const apiPRD = 'https://api-phr.hktdc.com'

const api_cms_SIT = 'https://api-cms-sit.hktdc.com'
const api_cms_UAT = 'https://api-cms-uat.hktdc.com'
const api_cms_PRD = 'https://api-cms.hktdc.com'

const files = {
  headerfooter: {
    contentType: "Common Sections/Header and Footer",
    fileName: "header-and-footer.xml"
  },
  announcement_home: {
    contentType: "Common Sections/Announcement",
    fileName: "announcement-home.xml"
  },
  announcement_source: {
    contentType: "Common Sections/Announcement",
    fileName: "announcement-source.xml"
  },
  announcement_promotion: {
    contentType: "Common Sections/Announcement",
    fileName: "promotion.xml"
  },
  announcement_join: {
    contentType: "Common Sections/Announcement",
    fileName: "join.xml"
  },
  announcement_sourcePromotion: {
    contentType: "Common Sections/Announcement",
    fileName: "sourcePromotion.xml"
  },
  topsearch: {
    contentType: "Common Sections/Top Keyword Search",
    fileName: "top_search_search.xml"
  },
  seo: {
    contentType: "Common Sections/SEO Data",
    fileName: "seo-data.xml"
  },
  event_calendar: {
    contentType: "Main Page/Event Calendar",
    fileName: "event-calendar.xml"
  },
  main_jumbotron_eventSlides: {
    contentType: "Main Page/Jumbotron",
    fileName: "jumbotron-yearmonth-%s.xml"
  },
  main_mf: {
    contentType: "Main Page/Market Focus",
    fileName: "market-focus.xml"
  },
  main_ss: {
    contentType: "Main Page/Success Stories",
    fileName: "success-stories.xml"
  },
  main_help: {
    contentType: "Main Page/Help",
    fileName: "help.xml"
  },
  page_start: {
    contentType: "Jump Pages/Start",
    fileName: "jump-start.xml"
  },
  page_join: {
    contentType: "Jump Pages/Join",
    fileName: "jump-join.xml"
  },
  page_source: {
    contentType: "Jump Pages/Source",
    fileName: "jump-source.xml"
  },
  page_explore: {
    contentType: "Jump Pages/Explore",
    fileName: "explore.xml"
  },
  page_mobileapp: {
    contentType: "Jump Pages/Mobile App",
    fileName: "mobile-app.xml"
  },
  page_ss: {
    contentType: "Jump Pages/Success Stories",
    fileName: "success-stories.xml"
  },
  page_staticPages: {
    contentType: "Jump Pages/Static Pages",
    fileName: "%s.xml"
  },
  page_AdvantagesMainPage: {
    contentType: "Jump Pages/Advantages Main Page",
    fileName: "main.xml"
  },
  page_AdvantagesThematicPage: {
    contentType: "Jump Pages/Advantages Thematic Page",
    fileName: "%s.xml"
  }
};

const apisSearch = {
  'all': 'phr-home/v1/all',
  'suppliers': 'phr-home/v1/supplier',
  'exhibitions-conferences': 'phr-home/v1/exhconf',
  'market-insights': 'phr-home/v1/mi',
  'others': 'phr-home/v1/other',
  'preferredListing': 'phr-home/v1/preferred-listing',
  'events': 'phr-home/v1/event',
  'ss': 'phr-success-story/v1/success-story',
  'factsheet': ''
}

const envList = [
  'localhost',
  'build_sit',
  'build_sit_preview',
  'build_uat',
  'build_uat_preview',
  'build_prod',
  'build_prod_preview',
];

let json_localhost = {};
let json_build_sit = {};
let json_build_sit_preview = {};
let json_build_uat = {};
let json_build_uat_preview = {};
let json_build_prod = {};
let json_build_prod_preview = {};

let api_search_localhost = {};
let api_search_build_sit = {};
let api_search_build_sit_preview = {};
let api_search_build_uat = {};
let api_search_build_uat_preview = {};
let api_search_build_prod = {};
let api_search_build_prod_preview = {};

envList.forEach(env => {
  let jsons = {};
  let apiSearchs = {};
  for (const key in files) {

    let genStaticLink = (domain) => {
      switch (key) {
        case 'announcement_home':
        case 'announcement_source':
          jsons[`json_${key}`] = `${domain}/${projectPath.static(projectName)}/${files[key].contentType.replace(/\s/g, '-')}/list/${files[key].fileName.replace(/xml/g, 'json')}`;
          break;
        default:
          jsons[`json_${key}`] = `${domain}/${projectPath.static(projectName)}/${filePath.static(files[key])}`;
          break;
      }
    }

    let genDynamicLink = (domain) => {
      let params = {
        ...defaultJSONParams,
        project: projectPath.dynamic(projectName),
        context: `${projectPath.dynamic(projectName)}/WORKAREA/default`
      };
      switch (key) {
        case 'announcement_home':
          params.q = `((TeamSite/Templating/DCR/Type:${files[key].contentType}) AND (displayOnPage:="home"))`;
          break;
        case 'announcement_source':
          params.q = `((TeamSite/Templating/DCR/Type:${files[key].contentType}) AND (displayOnPage:="source"))`;
          break;
        case 'announcement_promotion':
          params.q = `((TeamSite/Templating/DCR/Type:${files[key].contentType}) AND (displayOnPage:="promotion"))`;
          break;
        case 'main_jumbotron_eventSlides':
          params.q = `(Jumbotron/YearMonth:%s) AND (Path:templatedata/${files[key].contentType})`;
          break;
        default:
          params.q = filePath.dynamic(files[key]);
          break;
      }
      jsons[`json_${key}`] = `${domain}?${joinWithN(params)}`;
    }

    switch (env) {
      case 'localhost':
        genStaticLink(s3);
        json_localhost = { ...jsons }
        break;
      case 'build_sit':
        genStaticLink(homeSIT);
        json_build_sit = { ...jsons }
        break;
      case 'build_sit_preview':
        genDynamicLink(previewSIT, 'cmsappdev01');
        json_build_sit_preview = { ...jsons }
        break;
      case 'build_uat':
        genStaticLink(homeUAT);
        json_build_uat = { ...jsons }
        break;
      case 'build_uat_preview':
        genDynamicLink(previewUAT, 'cmsappuat01');
        json_build_uat_preview = { ...jsons }
        break;
      case 'build_prod':
        genStaticLink(homePRD);
        json_build_prod = { ...jsons }
        break;
      case 'build_prod_preview':
        genDynamicLink(previewPRD, 'cmsappprd01');
        json_build_prod_preview = { ...jsons }
        break;
    }
  }

  for (const key in apisSearch) {

    let genSearchLink = (domain) => {
      apiSearchs[`api_search_${key}`] = `${domain}/${apisSearch[key]}`
    }

    switch (env) {
      case 'localhost':
        genSearchLink(apiSIT);
        api_search_localhost = { ...apiSearchs }
        break;
      case 'build_sit':
        genSearchLink(apiSIT);
        api_search_build_sit = { ...apiSearchs }
        break;
      case 'build_sit_preview':
        genSearchLink(apiSIT);
        api_search_build_sit_preview = { ...apiSearchs }
        break;
      case 'build_uat':
        genSearchLink(apiUAT);
        api_search_build_uat = { ...apiSearchs }
        break;
      case 'build_uat_preview':
        genSearchLink(apiUAT);
        api_search_build_uat_preview = { ...apiSearchs }
        break;
      case 'build_prod':
        genSearchLink(apiPRD);
        api_search_build_prod = { ...apiSearchs }
        break;
      case 'build_prod_preview':
        genSearchLink(apiPRD);
        api_search_build_prod_preview = { ...apiSearchs }
        break;
    }
  }
})

let localhost = {
  'www': 'www-sit.hktdc.com',
  'home': 'home-sit.hktdc.com',
  'events': 'event-sit.hktdc.com',
  'urlRoot': '/',
  'urlEvent': '/urlEventPath',
  'subDirectoryPath': '',
  'full_www': '//www-sit.hktdc.com',
  'full_home': '//home-sit.hktdc.com',
  'full_events': '//event-sit.hktdc.com',
  'full_assets': '//home-sit.hktdc.com',
  ...json_localhost,
  ...api_search_localhost,
  'api_search_auto_complete': 'https://api-search-uat.hktdc.com/autocomplete/v1/autocomplete-search',
  'api_search_didyoumean': 'https://api-search-uat.hktdc.com/didyoumean/v1/didyoumean-search',
  'api_event_calendar': `https:${homeUAT}/resources/calendar/%s/%s-%s-calendar_%s.json`,
  'api_search_ss_filters': `${apiSIT}/phr-success-story/v1/success-story-filters`,
  'api_tealium_token': `${api_cms_SIT}/tealium/v1/auth`,
  'api_tealium_get_profile': 'https://api.tealiumiq.com/v2/visitor/accounts/hktdc/profiles/main?attributeId=%s&attributeValue=%s&prettyName=true',
  'api_factsheet': `${api_cms_SIT}/ls/v2/s/?project=//cmsapp/trade-event&q=((Path:/%s.xml) AND (TeamSite/Templating/DCR/Type:Pages/Trade Event) AND (eventStatus:Y))&dateCondition=true&t=[DATETIME]`,
  'api_factsheet_V2': `${api_cms_SIT}/ls/v2/s/?project=//cmsapp/Factsheet&q=((Path:/%s.xml) AND (TeamSite/Templating/DCR/Type:Factsheet/Factsheet) AND (factSheetStatus:Y))&dateCondition=true&t=[DATETIME]`,
  'api_webcast': `${api_cms_SIT}/ls/v2/s/?project=//cmsapp/multimedia&q=((Path:/%s.xml) AND (TeamSite/Templating/DCR/Type:multimedia/Webcast Article))&dateCondition=false`,
  'api_podcast': `${api_cms_SIT}/ls/v2/s/?project=//cmsapp/multimedia&q=((Path:/%s.xml) AND (TeamSite/Templating/DCR/Type:multimedia/Podcast Article))&dateCondition=false`,
  'api_ems_event': 'https://api-cms-sit.hktdc.com/ems/v1/event',
  feedback: {
    en: {
      desktop: 'http://empwebuat.hktdc.com/old/',
      url: 'https://www.surveygizmo.com/s3/4500586/HKTDC-New-Portal-Questionnaire-V2',
      mobile: 'http://m.hktdc.com/'
    },
    tc: {
      desktop: 'http://empwebuat.hktdc.com/old/tc',
      url: 'https://www.surveygizmo.com/s3/4524069/HKTDC-New-Portal-Questionnaire-TC-Test',
      mobile: 'http://m.hktdc.com/tc'
    },
    sc: {
      desktop: 'http://empwebuat.hktdc.com/old/sc',
      url: 'https://www.surveygizmo.com/s3/4524149/HKTDC-New-Portal-Questionnaire-SC-Test',
      mobile: 'http://m.hktdc.com/sc'
    }
  },
  adGlare_host: '//a-engine.hktdc.com/',
  adGlare_id: 986666215,
  search_more_suppliers: 'http://empwebsit.hktdc.com/sourcing/product_searchresult.htm?bookmark=true&query=%KEYWORD%&locale=%LOCALE%',
  myhktdc_link: 'http://empwebuat.hktdc.com/myhktdc/index.htm?locale=%LANG%',
  locationServiceEndPoint: 'https://api-general-uat.hktdc.com/location/v1/location-service',
  sourcejump_searchurl: 'https://sourcing-online-uat.origin-aws.hktdc.com/%s/%s/%s/1?sort=BEST_MATCH'
};

let build_sit = {
  'www': 'home-sit.hktdc.com',
  'home': 'home-sit.hktdc.com',
  'events': 'event-sit.hktdc.com',
  'urlRoot': '/',
  'urlEvent': '/urlEventPath',
  'subDirectoryPath': '',
  'full_www': '//home-sit.hktdc.com',
  'full_home': '//home-sit.hktdc.com',
  'full_events': '//event-sit.hktdc.com',
  'full_assets': '//home-sit.hktdc.com',
  ...json_build_sit,
  ...api_search_build_sit,
  'api_search_auto_complete': 'https://api-search-sit.hktdc.com/autocomplete/v1/autocomplete-search',
  'api_search_didyoumean': 'https://api-search-sit.hktdc.com/didyoumean/v1/didyoumean-search',
  'api_event_calendar': `${homeUAT}/resources/calendar/%s/%s-%s-calendar_%s.json`,
  'api_search_ss_filters': `${apiUAT}/phr-success-story/v1/success-story-filters`,

  'api_tealium_token': `${api_cms_SIT}/tealium/v1/auth`,
  'api_tealium_get_profile': 'https://api.tealiumiq.com/v2/visitor/accounts/hktdc/profiles/main?attributeId=%s&attributeValue=%s&prettyName=true',
  'api_factsheet': `${api_cms_SIT}/ls/v2/s/?project=//cmsapp/trade-event&q=((Path:/%s.xml) AND (TeamSite/Templating/DCR/Type:Pages/Trade Event) AND (eventStatus:Y))&dateCondition=true&t=[DATETIME]`,
  'api_factsheet_V2': `${api_cms_SIT}/ls/v2/s/?project=//cmsapp/Factsheet&q=((Path:/%s.xml) AND (TeamSite/Templating/DCR/Type:Factsheet/Factsheet) AND (factSheetStatus:Y))&dateCondition=true&t=[DATETIME]`,
  'api_public_event': ``,
  'api_webcast': `${api_cms_SIT}/ls/v2/s/?project=//cmsapp/multimedia&q=((Path:/%s.xml) AND (TeamSite/Templating/DCR/Type:multimedia/Webcast Article))&dateCondition=false`,
  'api_podcast': `${api_cms_SIT}/ls/v2/s/?project=//cmsapp/multimedia&q=((Path:/%s.xml) AND (TeamSite/Templating/DCR/Type:multimedia/Podcast Article))&dateCondition=false`,
  'api_ems_event': 'https://api-cms-sit.hktdc.com/ems/v1/event',
  feedback: {
    en: {
      desktop: 'http://empwebuat.hktdc.com/old/',
      url: 'https://www.surveygizmo.com/s3/4500586/HKTDC-New-Portal-Questionnaire-V2',
      mobile: 'http://m.hktdc.com/'
    },
    tc: {
      desktop: 'http://empwebuat.hktdc.com/old/tc',
      url: 'https://www.surveygizmo.com/s3/4524069/HKTDC-New-Portal-Questionnaire-TC-Test',
      mobile: 'http://m.hktdc.com/tc'
    },
    sc: {
      desktop: 'http://empwebuat.hktdc.com/old/sc',
      url: 'https://www.surveygizmo.com/s3/4524149/HKTDC-New-Portal-Questionnaire-SC-Test',
      mobile: 'http://m.hktdc.com/sc'
    }
  },
  adGlare_host: '//a-engine.hktdc.com/',
  adGlare_id: 986666215,
  search_more_suppliers: 'http://empwebsit.hktdc.com/sourcing/product_searchresult.htm?bookmark=true&query=%KEYWORD%&locale=%LOCALE%',
  myhktdc_link: 'http://empwebuat.hktdc.com/myhktdc/index.htm?locale=%LANG%',
  locationServiceEndPoint: 'https://api-general-uat.hktdc.com/location/v1/location-service',
  sourcejump_searchurl: 'https://sourcing-online-uat.origin-aws.hktdc.com/%s/%s/%s/1?sort=BEST_MATCH'
};

// SIT Preview
let build_sit_preview = {
  'www': 'cmsappdev01.hktdc.org',
  'home': 'cmsappdev01.hktdc.org',
  'events': 'cmsappdev01.hktdc.org',
  'urlRoot': '/',
  'urlEvent': '/urlEventPath',
  'subDirectoryPath': '/preview/phr',
  'full_www': 'http://cmsappdev01.hktdc.org/preview/phr',
  'full_home': 'http://cmsappdev01.hktdc.org/preview/phr',
  'full_events': 'http://cmsappdev01.hktdc.org/preview/phr',
  'full_assets': 'http://cmsappdev01.hktdc.org/preview/phr',
  ...json_build_sit_preview,
  ...api_search_build_sit_preview,
  'api_search_auto_complete': 'https://api-search-sit.hktdc.com/autocomplete/v1/autocomplete-search',
  'api_search_didyoumean': 'https://api-search-sit.hktdc.com/didyoumean/v1/didyoumean-search',
  'api_event_calendar': `${homeUAT}/resources/calendar/%s/%s-%s-calendar_%s.json`,
  'api_search_ss_filters': `${apiUAT}/phr-success-story/v1/success-story-filters`,
  'api_search_events': `${previewSIT}$`,
  'api_tealium_token': `${api_cms_SIT}/tealium/v1/auth`,
  'api_tealium_get_profile': 'https://api.tealiumiq.com/v2/visitor/accounts/hktdc/profiles/main?attributeId=%s&attributeValue=%s&prettyName=true',
  'api_factsheet': `${previewSIT}$?project=/default/main/trade-event&format=json&start=0&max=100&includeDCRContent=true&context=/default/main/trade-event/WORKAREA/default&sameOrigin=true&q=((Path:/%s.xml) AND (TeamSite/Templating/DCR/Type:Pages/Trade Event))`,
  'api_factsheet_V2': `${previewSIT}$?project=/default/main/Factsheet&format=json&start=0&max=100&includeDCRContent=true&context=/default/main/Factsheet/WORKAREA/default&sameOrigin=true&q=((Path:/%s.xml) AND (TeamSite/Templating/DCR/Type:Factsheet/Factsheet))`,
  'api_webcast': `${previewSIT}$?project=/default/main/multimedia&format=json&start=0&max=100&includeDCRContent=true&context=/default/main/multimedia/WORKAREA/default&sameOrigin=true&q=((Path:/%s.xml) AND (TeamSite/Templating/DCR/Type:multimedia/Webcast Article))`,
  'api_podcast': `${previewSIT}$?project=/default/main/multimedia&format=json&start=0&max=100&includeDCRContent=true&context=/default/main/multimedia/WORKAREA/default&sameOrigin=true&q=((Path:/%s.xml) AND (TeamSite/Templating/DCR/Type:multimedia/Podcast Article))`,
  'api_ems_event': 'https://api-cms-sit.hktdc.com/ems/v1/event',
  feedback: {
    en: {
      desktop: 'http://empwebuat.hktdc.com/old/',
      url: 'https://www.surveygizmo.com/s3/4500586/HKTDC-New-Portal-Questionnaire-V2',
      mobile: 'http://m.hktdc.com/'
    },
    tc: {
      desktop: 'http://empwebuat.hktdc.com/old/tc',
      url: 'https://www.surveygizmo.com/s3/4524069/HKTDC-New-Portal-Questionnaire-TC-Test',
      mobile: 'http://m.hktdc.com/tc'
    },
    sc: {
      desktop: 'http://empwebuat.hktdc.com/old/sc',
      url: 'https://www.surveygizmo.com/s3/4524149/HKTDC-New-Portal-Questionnaire-SC-Test',
      mobile: 'http://m.hktdc.com/sc'
    }
  },
  adGlare_host: '//a-engine.hktdc.com/',
  adGlare_id: 986666215,
  search_more_suppliers: 'http://empwebsit.hktdc.com/sourcing/product_searchresult.htm?bookmark=true&query=%KEYWORD%&locale=%LOCALE%',
  myhktdc_link: 'http://empwebuat.hktdc.com/myhktdc/index.htm?locale=%LANG%',
  locationServiceEndPoint: 'https://api-general-uat.hktdc.com/location/v1/location-service',
  sourcejump_searchurl: 'https://sourcing-online-uat.origin-aws.hktdc.com/%s/%s/%s/1?sort=BEST_MATCH'
};

let build_uat = {
  'www': 'home-uat.hktdc.com',
  'home': 'home-uat.hktdc.com',
  'events': 'event-uat.hktdc.com',
  'urlRoot': '/',
  'urlEvent': '/urlEventPath',
  'subDirectoryPath': '',
  'full_www': '//home-uat.hktdc.com',
  'full_home': '//home-uat.hktdc.com',
  'full_events': '//event-uat.hktdc.com',
  'full_assets': '//home-uat.hktdc.com',
  ...json_build_uat,
  ...api_search_build_uat,
  'api_search_auto_complete': 'https://api-search-uat.hktdc.com/autocomplete/v1/autocomplete-search',
  'api_search_didyoumean': 'https://api-search-uat.hktdc.com/didyoumean/v1/didyoumean-search',
  'api_event_calendar': `${homeUAT}/resources/calendar/%s/%s-%s-calendar_%s.json`,
  'api_search_ss_filters': `${apiUAT}/phr-success-story/v1/success-story-filters`,

  'api_tealium_token': `${api_cms_UAT}/tealium/v1/auth`,
  'api_tealium_get_profile': 'https://api.tealiumiq.com/v2/visitor/accounts/hktdc/profiles/main?attributeId=%s&attributeValue=%s&prettyName=true',
  'api_factsheet': `${api_cms_UAT}/ls/v2/s/?project=//cmsapp/trade-event&q=((Path:/%s.xml) AND (TeamSite/Templating/DCR/Type:Pages/Trade Event) AND (eventStatus:Y))&dateCondition=true&t=[DATETIME]`,
  'api_factsheet_V2': `${api_cms_UAT}/ls/v2/s/?project=//cmsapp/Factsheet&q=((Path:/%s.xml) AND (TeamSite/Templating/DCR/Type:Factsheet/Factsheet) AND (factSheetStatus:Y))&dateCondition=true&t=[DATETIME]`,
  'api_webcast': `${api_cms_UAT}/ls/v2/s/?project=//cmsapp/multimedia&q=((Path:/%s.xml) AND (TeamSite/Templating/DCR/Type:multimedia/Webcast Article))&dateCondition=false`,
  'api_podcast': `${api_cms_UAT}/ls/v2/s/?project=//cmsapp/multimedia&q=((Path:/%s.xml) AND (TeamSite/Templating/DCR/Type:multimedia/Podcast Article))&dateCondition=false`,
  'api_ems_event': 'https://api-cms-uat.hktdc.com/ems/v1/event',
  feedback: {
    en: {
      desktop: 'http://empwebuat.hktdc.com/old/',
      url: 'https://www.surveygizmo.com/s3/4500586/HKTDC-New-Portal-Questionnaire-V2',
      mobile: 'http://m.hktdc.com/'
    },
    tc: {
      desktop: 'http://empwebuat.hktdc.com/old/tc',
      url: 'https://www.surveygizmo.com/s3/4524069/HKTDC-New-Portal-Questionnaire-TC-Test',
      mobile: 'http://m.hktdc.com/tc'
    },
    sc: {
      desktop: 'http://empwebuat.hktdc.com/old/sc',
      url: 'https://www.surveygizmo.com/s3/4524149/HKTDC-New-Portal-Questionnaire-SC-Test',
      mobile: 'http://m.hktdc.com/sc'
    }
  },
  adGlare_host: '//a-engine.hktdc.com/',
  adGlare_id: 986666215,
  search_more_suppliers: 'http://empwebuat.hktdc.com/sourcing/product_searchresult.htm?bookmark=true&query=%KEYWORD%&locale=%LOCALE%',
  myhktdc_link: 'http://empwebuat.hktdc.com/myhktdc/index.htm?locale=%LANG%',
  locationServiceEndPoint: 'https://api-general-uat.hktdc.com/location/v1/location-service',
  sourcejump_searchurl: 'https://sourcing-online-uat.origin-aws.hktdc.com/%s/%s/%s/1?sort=BEST_MATCH'
};

// UAT Preview
let build_uat_preview = {
  'www': 'cmsappuat01.hktdc.org',
  'home': 'cmsappuat01.hktdc.org',
  'events': 'cmsappuat01.hktdc.org',
  'urlRoot': '/',
  'urlEvent': '/urlEventPath',
  'subDirectoryPath': '/preview/phr',
  'full_www': 'http://cmsappuat01.hktdc.org/preview/phr',
  'full_home': 'http://cmsappuat01.hktdc.org/preview/phr',
  'full_events': 'http://cmsappuat01.hktdc.org/preview/phr',
  'full_assets': 'http://cmsappuat01.hktdc.org/preview/phr',
  ...json_build_uat_preview,
  ...api_search_build_uat_preview,
  'api_search_auto_complete': 'https://api-search-uat.hktdc.com/autocomplete/v1/autocomplete-search',
  'api_search_didyoumean': 'https://api-search-uat.hktdc.com/didyoumean/v1/didyoumean-search',
  'api_event_calendar': `${homeUAT}/resources/calendar/%s/%s-%s-calendar_%s.json`,
  'api_search_ss_filters': `${apiUAT}/phr-success-story/v1/success-story-filters`,
  'api_search_events': `${previewUAT}$`,
  'api_tealium_token': `${api_cms_UAT}/tealium/v1/auth`,
  'api_tealium_get_profile': 'https://api.tealiumiq.com/v2/visitor/accounts/hktdc/profiles/main?attributeId=%s&attributeValue=%s&prettyName=true',
  'api_factsheet': `${previewUAT}$?project=/default/main/trade-event&format=json&start=0&max=100&includeDCRContent=true&context=/default/main/trade-event/WORKAREA/default&sameOrigin=true&q=((Path:/%s.xml) AND (TeamSite/Templating/DCR/Type:Pages/Trade Event))`,
  'api_factsheet_V2': `${previewUAT}$?project=/default/main/Factsheet&format=json&start=0&max=100&includeDCRContent=true&context=/default/main/Factsheet/WORKAREA/default&sameOrigin=true&q=((Path:/%s.xml) AND (TeamSite/Templating/DCR/Type:Factsheet/Factsheet))`,
  'api_webcast': `${previewUAT}$?project=/default/main/multimedia&format=json&start=0&max=100&includeDCRContent=true&context=/default/main/multimedia/WORKAREA/default&sameOrigin=true&q=((Path:/%s.xml) AND (TeamSite/Templating/DCR/Type:multimedia/Webcast Article))`,
  'api_podcast': `${previewUAT}$?project=/default/main/multimedia&format=json&start=0&max=100&includeDCRContent=true&context=/default/main/multimedia/WORKAREA/default&sameOrigin=true&q=((Path:/%s.xml) AND (TeamSite/Templating/DCR/Type:multimedia/Podcast Article))`,
  'api_ems_event': 'https://api-cms-uat.hktdc.com/ems/v1/event',
  feedback: {
    en: {
      desktop: 'http://empwebuat.hktdc.com/old/',
      url: 'https://www.surveygizmo.com/s3/4500586/HKTDC-New-Portal-Questionnaire-V2',
      mobile: 'http://m.hktdc.com/'
    },
    tc: {
      desktop: 'http://empwebuat.hktdc.com/old/tc',
      url: 'https://www.surveygizmo.com/s3/4524069/HKTDC-New-Portal-Questionnaire-TC-Test',
      mobile: 'http://m.hktdc.com/tc'
    },
    sc: {
      desktop: 'http://empwebuat.hktdc.com/old/sc',
      url: 'https://www.surveygizmo.com/s3/4524149/HKTDC-New-Portal-Questionnaire-SC-Test',
      mobile: 'http://m.hktdc.com/sc'
    }
  },
  adGlare_host: '//a-engine.hktdc.com/',
  adGlare_id: 986666215,
  search_more_suppliers: 'http://empwebuat.hktdc.com/sourcing/product_searchresult.htm?bookmark=true&query=%KEYWORD%&locale=%LOCALE%',
  myhktdc_link: 'http://empwebuat.hktdc.com/myhktdc/index.htm?locale=%LANG%',
  locationServiceEndPoint: 'https://api-general-uat.hktdc.com/location/v1/location-service',
  sourcejump_searchurl: 'https://sourcing-online-uat.origin-aws.hktdc.com/%s/%s/%s/1?sort=BEST_MATCH'
};

let build_prod = {
  'www': 'www.hktdc.com',
  'home': 'home.hktdc.com',
  'events': 'event.hktdc.com',
  'urlRoot': '/',
  'urlEvent': '/urlEventPath',
  'subDirectoryPath': '',
  'full_www': '//www.hktdc.com',
  'full_home': '//home.hktdc.com',
  'full_events': '//event.hktdc.com',
  'full_assets': '//home.hktdc.com',
  ...json_build_prod,
  ...api_search_build_prod,
  'api_search_auto_complete': 'https://api-search.hktdc.com/autocomplete/v1/autocomplete-search',
  'api_search_didyoumean': 'https://api-search.hktdc.com/didyoumean/v1/didyoumean-search',
  'api_event_calendar': `${homePRD}/resources/calendar/%s/%s-%s-calendar_%s.json`,
  'api_search_ss_filters': `${apiPRD}/phr-success-story/v1/success-story-filters`,

  'api_tealium_token': `${api_cms_PRD}/tealium/v1/auth`,
  'api_tealium_get_profile': 'https://api.tealiumiq.com/v2/visitor/accounts/hktdc/profiles/main?attributeId=%s&attributeValue=%s&prettyName=true',
  'api_factsheet': `${api_cms_PRD}/ls/v2/s/?project=//cmsapp/trade-event&q=((Path:/%s.xml) AND (TeamSite/Templating/DCR/Type:Pages/Trade Event) AND (eventStatus:Y))&dateCondition=true&t=[DATETIME]`,
  'api_factsheet_V2': `${api_cms_PRD}/ls/v2/s/?project=//cmsapp/Factsheet&q=((Path:/%s.xml) AND (TeamSite/Templating/DCR/Type:Factsheet/Factsheet) AND (factSheetStatus:Y))&dateCondition=true&t=[DATETIME]`,
  'api_webcast': `${api_cms_PRD}/ls/v2/s/?project=//cmsapp/multimedia&q=((Path:/%s.xml) AND (TeamSite/Templating/DCR/Type:multimedia/Webcast Article))&dateCondition=false`,
  'api_podcast': `${api_cms_PRD}/ls/v2/s/?project=//cmsapp/multimedia&q=((Path:/%s.xml) AND (TeamSite/Templating/DCR/Type:multimedia/Podcast Article))&dateCondition=false`,
  'api_ems_event': 'https://api-cms.hktdc.com/ems/v1/event',
  feedback: {
    en: {
      desktop: 'http://www.hktdc.com/old/',
      url: 'http://survey.hktdc.com/s3/HKTDC-Website-Questionnaire',
      mobile: 'http://m.hktdc.com/'
    },
    tc: {
      desktop: 'http://www.hktdc.com/old/tc',
      url: 'http://survey.hktdc.com/s3/HKTDC-Website-Questionnaire-TC',
      mobile: 'http://m.hktdc.com/tc'
    },
    sc: {
      desktop: 'http://www.hktdc.com/old/sc',
      url: 'http://survey.hktdc.com/s3/HKTDC-Website-Questionnaire-SC',
      mobile: 'http://m.hktdc.com/sc'
    }
  },
  adGlare_host: '//a-engine.hktdc.com/',
  adGlare_id: 544794003,
  search_more_suppliers: 'http://www.hktdc.com/sourcing/product_searchresult.htm?bookmark=true&query=%KEYWORD%&locale=%LOCALE%',
  myhktdc_link: 'http://www.hktdc.com/myhktdc/index.htm?locale=%LANG%',
  locationServiceEndPoint: 'https://api-general.hktdc.com/location/v1/location-service',
  sourcejump_searchurl: 'https://sourcing.hktdc.com/%s/%s/%s/1'
};

// PRODUCTION Preview
let build_prod_preview = {
  'www': 'cms.tdc.org.hk',
  'home': 'cms.tdc.org.hk',
  'events': 'cms.tdc.org.hk',
  'urlRoot': '/',
  'subDirectoryPath': '/preview/phr',
  'full_www': 'http://cms.tdc.org.hk/preview/phr',
  'full_home': 'http://cms.tdc.org.hk/preview/phr',
  'full_events': 'http://cms.tdc.org.hk/preview/phr',
  'full_assets': 'http://cms.tdc.org.hk/preview/phr',
  ...json_build_prod_preview,
  ...api_search_build_prod_preview,
  'api_search_auto_complete': 'https://api-search.hktdc.com/autocomplete/v1/autocomplete-search',
  'api_search_didyoumean': 'https://api-search.hktdc.com/didyoumean/v1/didyoumean-search',
  'api_event_calendar': '//home.hktdc.com/resources/calendar/%s/%s-%s-calendar_%s.json',
  'api_search_ss_filters': `${apiPRD}/phr-success-story/v1/success-story-filters`,
  'api_search_events': `${previewPRD}$`,
  'api_tealium_token': `${api_cms_PRD}/tealium/v1/auth`,
  'api_tealium_get_profile': 'https://api.tealiumiq.com/v2/visitor/accounts/hktdc/profiles/main?attributeId=%s&attributeValue=%s&prettyName=true',
  'api_factsheet': `${previewPRD}$?project=/default/main/trade-event&format=json&start=0&max=100&includeDCRContent=true&context=/default/main/trade-event/WORKAREA/default&sameOrigin=true&q=((Path:/%s.xml) AND (TeamSite/Templating/DCR/Type:Pages/Trade Event))`,
  'api_factsheet_V2': `${previewPRD}$?project=/default/main/Factsheet&format=json&start=0&max=100&includeDCRContent=true&context=/default/main/Factsheet/WORKAREA/default&sameOrigin=true&q=((Path:/%s.xml) AND (TeamSite/Templating/DCR/Type:Factsheet/Factsheet))`,
  'api_webcast': `${previewPRD}$?project=/default/main/multimedia&format=json&start=0&max=100&includeDCRContent=true&context=/default/main/multimedia/WORKAREA/default&sameOrigin=true&q=((Path:/%s.xml) AND (TeamSite/Templating/DCR/Type:multimedia/Webcast Article))`,
  'api_podcast': `${previewPRD}$?project=/default/main/multimedia&format=json&start=0&max=100&includeDCRContent=true&context=/default/main/multimedia/WORKAREA/default&sameOrigin=true&q=((Path:/%s.xml) AND (TeamSite/Templating/DCR/Type:multimedia/Podcast Article))`,
  'api_ems_event': 'https://api-cms.hktdc.com/ems/v1/event',
  feedback: {
    en: {
      desktop: 'http://www.hktdc.com/old/',
      url: 'http://survey.hktdc.com/s3/HKTDC-Website-Questionnaire',
      mobile: 'http://m.hktdc.com/'
    },
    tc: {
      desktop: 'http://www.hktdc.com/old/tc',
      url: 'http://survey.hktdc.com/s3/HKTDC-Website-Questionnaire-TC',
      mobile: 'http://m.hktdc.com/tc'
    },
    sc: {
      desktop: 'http://www.hktdc.com/old/sc',
      url: 'http://survey.hktdc.com/s3/HKTDC-Website-Questionnaire-SC',
      mobile: 'http://m.hktdc.com/sc'
    }
  },
  adGlare_host: '//a-engine.hktdc.com/',
  adGlare_id: 544794003,
  search_more_suppliers: 'http://www.hktdc.com/sourcing/product_searchresult.htm?bookmark=true&query=%KEYWORD%&locale=%LOCALE%',
  myhktdc_link: 'http://www.hktdc.com/myhktdc/index.htm?locale=%LANG%',
  locationServiceEndPoint: 'https://api-general.hktdc.com/location/v1/location-service',
  sourcejump_searchurl: 'https://sourcing.hktdc.com/%s/%s/%s/1'
};


let config = '';

switch (process.env.REACT_APP_STAGE) {
  case 'build_sit':
    config = build_sit;
    break;
  case 'build_uat':
    config = build_uat;
    break;
  case 'build_sit_preview':
    config = build_sit_preview;
    break;
  case 'build_uat_preview':
    config = build_uat_preview;
    break;
  case 'build_prod':
    config = build_prod;
    break;
  case 'build_prod_preview':
    config = build_prod_preview;
    break;
  default:
    config = localhost;
    break;
}

export default {
  // Add common config values here
  'pagename_business': 'start-grow-business',
  'pagename_events': 'join-fairs-conferences-events',
  'pagename_hkevents': 'more-hongkong-events',
  'pagename_outsideevents': 'more-outside-hongkong-events',
  'pagename_services': 'source-products-services',
  'pagename_insights': 'explore-industry-market-insights',
  'pagename_successStories': 'success-stories',
  'pagename_mobileapp': 'mobile-apps',
  'pagename_search': 'search',
  'pagename_textsize': 's/textsize',
  'pagename_serviceTradeEvents': 'service-trade-events',
  'pagename_merchandiseTradeEvents': 'merchandise-trade-events',
  'pagename_hkadvantages': 'Hong Kong Advantages',
  'pagename_hkadvantagesSustainability': 'Hong Kong Advantages - Sustainability',
  'pagename_hkadvantagesWellness': 'Hong Kong Advantages - Wellness',
  'pagename_hkadvantagesInnovation': 'Hong Kong Advantages - Innovation',

  searchTypes: ['all', 'exhibitions-conferences', 'suppliers', 'market-insights', 'others'],
  mapname: ['business', 'events', 'services', 'insights'],

  'allowedTags': ['h2', 'h3', 'h4', 'h5', 'h6', 'blockquote', 'p', 'a', 'img', 'ul', 'ol', 'nl', 'li', 'b', 'i', 'strong', 'em', 'strike', 'code', 'hr', 'br', 'div', 'table', 'thead', 'caption', 'tbody', 'tr', 'th', 'td', 'pre', 'span', 'iframe', 'style', 'video', 'colgroup', 'col'],
  'allowedAttributes': {
    a: ['class', 'href', 'name', 'target', 'data-target', 'data-content'],
    // We don't currently allow img itself by default, but this
    // would make sense if we did
    img: ['class', 'src', 'data-target', 'data-content'],
    span: ['class', 'style', 'data-target', 'data-content'],
    h3: ['class', 'style'],
    h4: ['class', 'style'],
    p: ['class', 'style', 'data-target', 'data-content'],
    div: ['class', 'style', 'data-target', 'data-content'],
    ul: ['class', 'style'],
    li: ['class', 'style'],
    ol: ['class', 'style', 'start', 'reversed', 'type'],
    iframe: ['class', 'width', 'height', 'src', 'frameBorder', 'allow', 'allowFullScreen'],
    table: ['class', 'style', 'id', 'border', 'cellpadding', 'cellspacing'],
    th: ['class', 'style', 'abbr', 'colspan', 'headers', 'rowspan', 'scope', 'sorted'],
    td: ['class', 'style', 'colspan', 'headers', 'rowspan', 'border'],
    tr: ['class', 'style'],
    video: ['class', 'style', 'src', 'controls', 'poster', 'autoplay'],
    col: ['class', 'style', 'span']
  },
  business_bg: [{
    backgroundImg: '/images/business/img-blue-building.jpg'
  },
  {
    backgroundColor: '#fb5004'
  },
  {},
  {
    backgroundColor: '#242655'
  },
  {
    backgroundImg: '/images/business/img-black-matching.jpg'
  }, {}
  ],
  services_bg: [{
    backgroundImg: '/images/services/img-online-marketplace.jpg'
  },
  {

  },
  {
    backgroundColor: '#242655'
  },
  {

  }
  ],
  explore_bg: [
    {
      backgroundColor: '#f6f6f6'
    },
    {
      backgroundColor: '#fafafa'
    },
    {},
    {
      backgroundColor: '#fb5004'
    },
    {
      backgroundColor: '#3a3531'
    },
    {
      backgroundColor: '#f6f6f6'
    },
    {}
  ],
  max_result_page: 500,
  awards_per_row: 10,
  ss_per_page: 9,
  search_min_char: 2,
  search_result_per_page: 20,
  search_api_sort_map: {
    asc: 'eventStartDate',
    desc: '-eventStartDate',
    latest: '-publishDate',
    oldest: 'publishDate'
  },
  search_prefer_group_map: {
    'all': 'all',
    'exhibitions-conferences': 'exhconf',
    'suppliers': 'supplier',
    'market-insights': 'mi',
    'others': 'other'
  },
  search_prefer_limit: 6,
  search_adsys_class: {
    en: 'D14,D24,D34',
    tc: 'D16,D26,D36',
    sc: 'D18,D28,D38'
  },
  search_sri_max_display: 8,
  search_contentType: {
    eventsch: 'eventsch',
    prodcat: 'prodcat',
    company: 'company',
    general: 'general',
    article: 'article',
    success: 'success',
    eventinfo: 'eventinfo',
  },
  search_resourceType: {
    success: 'successstory',
    hkmb: 'hkmbarticle'
  },
  eventsFilter: ['industries', 'eventFormat', 'countryRegion', 'year'],
  eventFormat: ['HongKongPavilion', 'TradeMission', 'SeminarWorkshop', 'ConferenceForum', 'Exhibition', 'Others'],
  eventFormat_image: {
    HongKongPavilion: '/images/events/cannedThumbs/Hong-Kong-Pavilion-01.jpg',
    TradeMission: '/images/events/cannedThumbs/Trade-Mission-01.jpg',
    SeminarWorkshop: '/images/events/cannedThumbs/Seminar-workshop-01.jpg',
    ConferenceForum: '/images/events/cannedThumbs/Conference-Forum-01.jpg',
    Exhibition: '/images/events/cannedThumbs/Exhibition-01.jpg',
    Others: '/images/events/cannedThumbs/Others-01.jpg'
  },
  ssFilter: ['industries', 'regions'],
  alert_cookie_expire: 365,
  api_lang_map: { en: 'en', tc: 'zh-Hant', sc: 'zh-Hans' },
  sso_lang_map: { en: 'en', tc: 'zh-tw', sc: 'zh-cn' },
  myhktdc_lang_map: { en: 'en', tc: 'zh_TW', sc: 'zh_CN' },
  sourcejumpsearch_lang_map: { en: 'en', tc: 'zh-Hant', sc: 'zh-Hans' },
  char_limit: {
    title: 80,
    desc: 200,
    companyName: 60,
    nob: 60,
    region: 125,
    ss_title: 75,
    ss_desc: 150
  },
  WTTealiumGroupName: {
    header: 'Header',
    submenu: 'Sub-menu',
    footer: 'footer',
    mainBanner: 'Main-banner',
    eventCalendar: 'Hktdc-events',
    marketFocus: 'Market-focus',
    mainSS: 'Success-stories',
    faq: 'FAQ',
    channelBar: 'Channel-bar',
    start: 'Start',
    join: 'Join',
    source: 'Source',
    explore: 'Explore',
    pageSS: 'Success Stories',
    mobile: 'Mobile',
    others: 'others'
  },
  tealium: {
    home: {
      subsection: "Home",
      pageType: "home",
      pageName: 'HKTDC Home'
    },
    search: {
      subsection: "Search Result",
      pageType: "search",
      pageName: "HKTDC Search Result"
    },
    events: {
      subsection: "Join Events",
      pageType: "section",
      pageName: 'HKTDC Join Fairs, Conference & Events'
    },
    hkEvents: {
      subsection: "Join Events",
      pageType: "section",
      pageName: "HKTDC More Hong Kong Events"
    },
    hkOutsideEvents: {
      subsection: "Join Events",
      pageType: "section",
      pageName: "HKTDC More Outside Hong Kong Events"
    },
    mobileApp: {
      subsection: "Mobile Apps",
      pageType: "section",
      pageName: "HKTDC Mobile Apps"
    },
    jumpSS: {
      subsection: "Success Stories",
      pageType: "section",
      pageName: "HKTDC Success Stories"
    },
    start: {
      subsection: "Start and Grow",
      pageType: "section",
      pageName: "HKTDC Start & Grow Your Business"
    },
    sourcing: {
      subsection: "Sourcing",
      pageType: "section",
      pageName: "HKTDC Source Product & Services"
    },
    explore: {
      subsection: "Explore",
      pageType: "section",
      pageName: "HKTDC Explore Industry & Market Insights"
    },
    staticPages: {
      subsection: "Others",
      pageType: "section"
    },
    factsheetPages: {
      subsection: "Event Detail",
      pageType: "section"
    },
    webcastPages: {
      subsection: "Webcast",
      pageType: "webcast"
    },
    podcastPages: {
      subsection: "Podcast",
      pageType: "podcast"
    },
    hkAdvantagePage: {
      subsection: "Hong Kong Advantages",
      pageType: "section"
    },
    notFoundPage: {
      subsection: "Other",
      pageType: "errorpage",
      pageName: "Page Not Found"
    }
  },
  "langLinks": [{
    "txt": "Čeština",
    "href": "https://czech.hktdc.com/"
  },
  {
    "txt": "Deutsch",
    "href": "https://german.hktdc.com/"
  },
  {
    "txt": "Español",
    "href": "https://espanol.hktdc.com/"
  },
  {
    "txt": "Français",
    "href": "https://french.hktdc.com/"
  },
  {
    "txt": "Italiano",
    "href": "https://italiano.hktdc.com/"
  },
  {
    "txt": "Polski",
    "href": "https://polish.hktdc.com/"
  },
  {
    "txt": "Português",
    "href": "https://portugues.hktdc.com/"
  },
  {
    "txt": "Pусский",
    "href": "https://russian.hktdc.com/"
  },
  {
    "txt": "عربى",
    "href": "https://arabic.hktdc.com/"
  },
  {
    "txt": "한국어",
    "href": "https://korean.hktdc.com/"
  },
  {
    "txt": "日本語",
    "href": "https://japanese.hktdc.com/"
  }
  ],
  feedbackCookieDuration: 30,
  tealium_attribute_id_ssoid: 5331,
  tealium_attribute_id_cookieid: 5749,
  sourcePageFairCode: 'hktoyfair,hkbabyfair,hkdgp,hkjewellery,hklightingfairse,hkelectronicsfairse,hkhousewarefair,hkgiftspremiumfair,hkelectronicsfairae,hklightingfairae',

  pastEventUrl: '?location=pastevent',
  ...config
};